// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-artists-js": () => import("./../../../src/templates/artists.js" /* webpackChunkName: "component---src-templates-artists-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-exhibition-js": () => import("./../../../src/templates/exhibition.js" /* webpackChunkName: "component---src-templates-exhibition-js" */),
  "component---src-templates-exhibitions-current-js": () => import("./../../../src/templates/exhibitions-current.js" /* webpackChunkName: "component---src-templates-exhibitions-current-js" */),
  "component---src-templates-exhibitions-js": () => import("./../../../src/templates/exhibitions.js" /* webpackChunkName: "component---src-templates-exhibitions-js" */),
  "component---src-templates-exhibitions-past-js": () => import("./../../../src/templates/exhibitions-past.js" /* webpackChunkName: "component---src-templates-exhibitions-past-js" */),
  "component---src-templates-exhibitions-upcoming-js": () => import("./../../../src/templates/exhibitions-upcoming.js" /* webpackChunkName: "component---src-templates-exhibitions-upcoming-js" */),
  "component---src-templates-front-js": () => import("./../../../src/templates/front.js" /* webpackChunkName: "component---src-templates-front-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-visit-js": () => import("./../../../src/templates/visit.js" /* webpackChunkName: "component---src-templates-visit-js" */)
}

