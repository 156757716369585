import React from 'react'
import Helmet from 'react-helmet'

import Menu from './Navbar'
import TheQ from './TheQ'
import Transition from './Transition'
import Footer from './Footer'
import './scss/all.scss'

import appleTouch from '../img/favicons/apple-touch-icon.png'
import favicon32 from '../img/favicons/favicon-32x32.png'
import favicon16 from '../img/favicons/favicon-16x16.png'

import Helper from './ResponsiveHelper'

const TemplateWrapper = ({ children, location }) => (
  <div className="site-content">
      
      <Helmet title="Q Contemporary" charSet="utf-8">
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouch} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      <Menu location={location} toggler="menu" />
      <TheQ />
      <div className="page" id="page">
        <Transition location={location}>
          {children}
        </Transition>
      </div>
    <Footer />
  </div>
)

export default TemplateWrapper
