import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export default class Subscribe extends React.Component {
    state = {
        name: null,
        email: null,
        message: 'Subscribe to our newsletter:',
        class: 'initial'
    }

    _handleChange = (e) => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }

    _handleSubmit = (e) => {
      e.preventDefault();

      addToMailchimp(this.state.email, this.state)
          .then(({ msg, result }) => {
              if (result !== 'success') {
                  throw msg;
              }
              this.setState({ message: msg, class: 'success'})
          })
          .catch((err) => {
              this.setState({ message: err, class: 'error'})
          });
    }

    render() {
        return (
            <div className="subscribe">
              <div className={this.state.class + ' message'} dangerouslySetInnerHTML={{__html: this.state.message }}/>
              <form onSubmit={this._handleSubmit}>
                <input
                    type="email"
                    onChange={this._handleChange}
                    placeholder="Your e-mail"
                    name="email"
                />
                <input className="button" type="submit" />
              </form>
            </div>
        );
    }
}