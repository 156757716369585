import React from 'react'

export default class Helper extends React.Component {
    render() {
        return (
            <div className="screen-size">
                <div className="screen-size__mobile">mobile</div>
                <div className="screen-size__tablet">tablet</div>
                <div className="screen-size__desktop">desktop</div>
                <div className="screen-size__widescreen">widescreen</div>
                <div className="screen-size__fullhd">fullhd</div>
            </div>
        )
    }
}