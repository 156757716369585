import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Subscribe from './MailchimpSubscribe'
import Reveal from 'react-reveal/Reveal';
import { Link } from 'gatsby'


const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressAcfOptions {
            edges {
                node {
                    id
                    options_footer {
                        locations_text
                        locations {
                            location
                        }
                        follow_us_text
                        social_media {
                            platform
                            link
                        }
                    }
                }
            }
        }
      }
    `}
    render={data => (
        <Reveal effect="slideInUp">
            <div className="footer" id="footer">
                {data.allWordpressAcfOptions.edges.map(edge => (
                    <div className="container" key={edge.node.id}>
                        <div className="columns is-multiline">
                            <div className="column is-12-tablet is-3-desktop has-text-weight-bold">
                                {edge.node.options_footer.locations_text}
                            </div>
                            {Object.keys(edge.node.options_footer.locations).map((i) => (
                                <div 
                                dangerouslySetInnerHTML={{
                                    __html: edge.node.options_footer.locations[i].location
                                }}
                                className="column is-4-tablet is-3-desktop" key={i} />
                            ))}
                             <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}} className="column is-6">
                                    <Link to="/press">
                                        Press Room
                                    </Link>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                    <Link to="/terms-and-conditions">
                                        Terms and Conditions
                                    </Link>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-3 has-text-weight-bold">
                                {edge.node.options_footer.follow_us_text}
                            </div>
                            <div className="column is-3">
                                {Object.keys(edge.node.options_footer.social_media).map((i) => (
                                    <a 
                                        href={edge.node.options_footer.social_media[i].link}
                                        key={i}
                                    >
                                        {edge.node.options_footer.social_media[i].platform}
                                    <br/></a>
                                ))}
                            </div>
                            <div className="column is-6">
                                <Subscribe/>
                            </div>
                        </div>
                        
                    </div>
                ))}
            </div>
        </Reveal>
    )}
  />
)

export default Footer
