import React, {Component} from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class ParentLink extends Component {
    state = {
      isActive: this.props.isActive
    };
  
    handleClick = () => {
      this.setState(state => ({ isActive: !state.isActive }));
    };

    click = () => {
        this.props.menuToggle();
    }
  
    render() {
      const menuClass = classNames({
        menu: true,
        active: this.state.isActive,
        parentItem: 'parent-item'
      });
  
      return (
          <>
            <div className={menuClass} onClick={this.handleClick}><span className="toggle-icon">{this.state.isActive ? "-" : "+"}</span> {this.props.item.title}</div>
            {this.state.isActive ? 
                <div className="children">
                    { this.props.item.wordpress_children.map(child => (
                        <Link
                            activeClassName="active"
                            id={child.object_slug}
                            to={`/${this.props.item.object_slug}/${child.object_slug}`}
                            key={child.object_slug}
                            partiallyActive={true}
                            onClick={this.click}
                        >
                        {child.title}
                        </Link>
                    ))}
                </div>
            : false }
          </>
      );
    }
  }
  
  ParentLink.propTypes = {
    children: PropTypes.node,
    item: PropTypes.object
  };
  
  ParentLink.defaultProps = {
    children: null
  };
  
  export default ParentLink;