import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import ParentLink from './ParentLink'
import classNames from 'classnames'


export const Navbar = ({ location, toggler, isOpen, menuToggle }) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpApiMenusMenusItems {
          items {
            title
            object_slug
            wordpress_children {
              title
              object_slug
            }
          }
        }
      }
    `}
    render={data => (
        <nav id="menu" className={isOpen ? 'navbar container is-nav-open' : 'navbar container' }>
            <div className="navbar__toggle">
              <div onClick={menuToggle} className="navbar-item">{toggler}</div>
            </div>
    
            <div className="navbar__menu">
                <div className="navbar__branding">
                  <Link onClick={menuToggle} 
                      to="/" className={"navbar-item active"}>
                      Q Contemporary
                  </Link>
                </div>
                {data.wordpressWpApiMenusMenusItems.items.map(item => (
                  <div className="navbar-item">
                    {item.wordpress_children ? 
                      <div>
                        <ParentLink menuToggle={menuToggle} isActive={location.pathname.includes(item.object_slug) ? true : false } item={item} />
                      </div>
                    : 
                      <Link
                        activeClassName="active"
                        id={item.object_slug}
                        to={`/${item.object_slug}`}
                        key={item.object_slug}
                        partiallyActive={true}
                        onClick={menuToggle} 
                      >
                        {item.title}
                      </Link> 
                    }

                  </div>
                ))}
            </div>
          <div className="navbar__overlay" ></div>
        </nav>
    )}
  />
)

export default class Menu extends React.Component {
  state = {
    current: 0,
    isMenuOpen: false
  };
  componentDidMount() {
    this.prev = window.scrollY;
    window.addEventListener('scroll', e => this.handleNavigation(e));
  }
  
  handleClick = () => {
    this.setState(state => ({ isMenuOpen: !state.isMenuOpen }));
  };

  handleNavigation = (e) => {
      const window = e.currentTarget;

      if (this.prev > window.scrollY + 20) {
        document.getElementById('menu').classList.remove('compact');
      }
      else if(this.prev < window.scrollY){
        document.getElementById('menu').classList.add('compact');
        document.getElementById('menu').classList.remove('is-nav-open');
      }
      this.prev = window.scrollY;
  };
  
  render() {
    const { location } = this.props;

    return (
      <div>
        <Navbar menuToggle={this.handleClick} isOpen={this.state.isMenuOpen} location={location} toggler="menu"/>
      </div>
    )
  }
}
